import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    createUser,
    retrieveUsers,
} from "../actions/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const UserCreate = forwardRef((props, ref) => {
	const organization_data = useSelector(state => state.OrganizationTabReducer, shallowEqual);
	const dispatch = useDispatch();
	const initialUserData = {
		organization_id: '',
		liability_waiver: '',
		coi: '',
		contact: '',
		contact_title: '',
		contact_email: '',
		contact_phone: '',
		country: '',
		state: '',
		city: '',
		zip: '',
		address: '',
		username: '',
		password: '',
		status: '',
		is_admin: '',
	};
	const [user_data, set_user_data] = React.useState(initialUserData);
	const [showModal, setShowModal] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	React.useImperativeHandle(ref, () => ({
		showUserCreateChildModal(single_user_data) {
			showUserCreateModal();
			set_user_data({
				sl_m_user_profile: single_user_data.sl_m_user_profile,
				organization_id: single_user_data.organization_id,
				liability_waiver: single_user_data.liability_waiver,
				coi: single_user_data.coi,
				contact: single_user_data.contact,
				contact_title: single_user_data.contact_title,
				contact_email: single_user_data.contact_email,
				contact_phone: single_user_data.contact_phone,
				country: single_user_data.country,
				state: single_user_data.state,
				city: single_user_data.city,
				zip: single_user_data.zip,
				address: single_user_data.address,
				username: single_user_data.username,
				password: single_user_data.password,
				status: single_user_data.status,
				is_admin: single_user_data.is_admin,
			});
		},
	}))
	const handleChange = (event) => {
		const { name, value } = event.target;
		set_user_data({ ...user_data, [name]: value });
	}
	const showUserCreateModal = () => {
		set_user_data(initialUserData);
	    setShowModal('show');
	}
	const hideUserCreateModal = () => {
	    setShowModal('');
	}
	const handleSubmitNewUserCreateFunc = () => {
		// console.log(user_data);
		dispatch(createUser(user_data))
		.then(data => {
			setSubmitted(true);
			if(data.res > 0 || data.count == 1) {
				hideUserCreateModal();
				toast.success(data.msg);
				// set_user_data(initialUserData);
				dispatch(retrieveUsers({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}
	return(
		<div className="mt-1">
			<button type="button" className="btn btn-primary btn-sm pull-right" onClick={showUserCreateModal}>
				Create
			</button>
			{(showModal == 'show')?<div className="modal-backdrop fade show"></div>:''}
			<div className={`modal fade f-OpenSans fs-14px ${showModal} `} id="userCreate" tabIndex="-1" aria-labelledby="userCreateTitle" aria-hidden="true" style={{display: (showModal == 'show')?"block":"none"}}>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="userCreateTitle">Create User Management</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideUserCreateModal}></button>
						</div>
						<div className="modal-body">
							<form>
								<h6>Organization info :</h6>
								<div className="container">
									<div className="row">
										<div className="mb-2 col-md-4">
											<label className="form-label">Enter organization</label>
											<select className="form-select form-select-sm" id="organization_id" name="organization_id" onChange={handleChange}>
											{
												(organization_data.length == 0)?<option value="">No Organizations</option>:
												(organization_data.data.length == 0)?<option value="">No Organizations</option>:<option value="">Choose Organization</option>
											}
											{
												(organization_data.length == 0)?'':
												organization_data.data &&
												organization_data.data.map((value, index) => {
													return (
														<option key={index} value={value.sl_m_organization_profile}>{value.organization_name}</option>
													);
												})
											}
											</select>
										</div>
										<div className="mb-2 col-md-4">
											<label className="form-label">Enter Liability Waiver</label>
											<input type="text" className="form-control form-control-sm" id="liability_waiver" value={user_data.liability_waiver} onChange={handleChange} name="liability_waiver"></input>
										</div>
										<div className="mb-2 col-md-4">
											<label className="form-label">Enter COI</label>
											<input type="text" className="form-control form-control-sm" id="coi" value={user_data.coi} onChange={handleChange} name="coi"></input>
										</div>
									</div>
								</div>
								<h6>User info :</h6>
								<div className="container">
									<div className="row">
										<div className="mb-2 col-md-6">
												<label className="form-label">Enter Contact</label>
											<input type="text" className="form-control form-control-sm" id="contact" value={user_data.contact} onChange={handleChange} name="contact"></input>
										</div>
										<div className="mb-2 col-md-6">
											<label className="form-label">Contact Title</label>
											<input type="text" className="form-control form-control-sm" id="contact_title" value={user_data.contact_title} onChange={handleChange} name="contact_title"></input>
										</div>
									</div>
									<div className="row">
										<div className="mb-2 col-md-6">
											<label className="form-label">Contact Email</label>
											<input type="text" className="form-control form-control-sm" id="contact_email" value={user_data.contact_email} onChange={handleChange} name="contact_email"></input>
										</div>
										<div className="mb-2 col-md-6">
											<label className="form-label">Contact Phone</label>
											<input type="text" className="form-control form-control-sm" id="contact_phone" value={user_data.contact_phone} onChange={handleChange} name="contact_phone"></input>
										</div>
									</div>
									<div className="row">
										<div className="mb-2 col-md-3">
											<label className="form-label">Enter Country</label>
											<input type="text" className="form-control form-control-sm" id="country" value={user_data.country} onChange={handleChange} name="country"></input>
										</div>
										<div className="mb-2 col-md-3">
											<label className="form-label">Enter State</label>
											<input type="text" className="form-control form-control-sm" id="state" value={user_data.state} onChange={handleChange} name="state"></input>
										</div>
										<div className="mb-2 col-md-3">
											<label className="form-label">Enter City</label>
											<input type="text" className="form-control form-control-sm" id="city" value={user_data.city} onChange={handleChange} name="city"></input>
										</div>
										<div className="mb-2 col-md-3">
											<label className="form-label">Enter Zip</label>
											<input type="text" className="form-control form-control-sm" id="zip" value={user_data.zip} onChange={handleChange} name="zip"></input>
										</div>
										<div className="mb-2 col-md-12">
											<label className="form-label">Address</label>
											<textarea type="text" className="form-control form-control-sm" id="address" value={user_data.address} onChange={handleChange} name="address"></textarea>
										</div>
									</div>
									<div className="row">
										<div className="mb-2 col-md-6">
											<label className="form-label">Enter Username</label>
											<input type="text" className="form-control form-control-sm" id="username" value={user_data.username} onChange={handleChange} name="username"></input>
										</div>
											<div className="mb-2 col-md-6">
											<label className="form-label">Enter Password</label>
											<input type="text" className="form-control form-control-sm" id="password" value={user_data.password} onChange={handleChange} name="password"></input>
										</div>
									</div>
									<div className="row">
										<div className="mb-2 col-md-6">
											<label className="form-label">Status</label>
											<select className="form-control form-control-sm" id="status" value={user_data.status} onChange={handleChange} name="status">
												<option value="1">Active</option>
												<option value="0">In-Active</option>
											</select>
										</div>
										<div className="mb-2 col-md-6">
											<label className="form-label">Is Admin</label>
												<select className="form-control form-control-sm" id="is_admin" value={user_data.is_admin} onChange={handleChange} name="is_admin">
												<option value="1">Yes</option>
												<option value="0">No</option>
											</select>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={hideUserCreateModal}>Close</button>
							<button type="button" className="btn btn-primary" onClick={handleSubmitNewUserCreateFunc}>Save changes</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
})

export default UserCreate;

