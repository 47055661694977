import React, {useState} from 'react';

import AllVisitSummary from './components/AllVisitSummary'
import './assets/css/index.css';
// import OrganizationFilter from './components/organizationfilter'
// import OrganizationList from './components/organizationlist'
// import UserFilter from './components/userfilter'
// import UserList from './components/userlist'



const VisitManagement = () => {
	const [percentage, setPercentage] = useState(20);
	return(
		<div className="container-fluid mt-1">
			<AllVisitSummary />
		</div>
	);
}

export default VisitManagement;