import http from "../../http-common.function";
var sha1 = require('sha1');


// Call APIs For Users


const createUser = (data) => {
  return http.post(`userManagement/ajaxview/createUser`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const fetchUserList = (data) => {
  return http.post(`userManagement/ajaxview/userList`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeUserStatus = (data) => {
  return http.post(`userManagement/ajaxview/changeUserStatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashUser = (data) => {
  return http.post(`userManagement/ajaxview/trashUser`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};




// Call APIs For Organization

const createOrganization = (data) => {
  return http.post(`organizationManagement/ajaxview/createOrganization`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const fetchOrganizationList = (data) => {
  return http.post(`organizationManagement/ajaxview/organizationList`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const changeOrganizationStatus = (data) => {
  return http.post(`organizationManagement/ajaxview/changeOrganizationStatus`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};
const trashOrganization = (data) => {
  return http.post(`organizationManagement/ajaxview/trashOrganization`, {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }, data
  });
};



const UserManagementPageService = {
  fetchUserList,
  createUser,
  changeUserStatus,
  trashUser,
  fetchOrganizationList,
  createOrganization,
  changeOrganizationStatus,
  trashOrganization,
};
export default UserManagementPageService;