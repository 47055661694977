export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const RETRIEVE_RESOURCE = "RETRIEVE_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";


export const CREATE_LOCATION = "CREATE_LOCATION";
export const RETRIEVE_LOCATION = "RETRIEVE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
