import React, {useState} from 'react';

import CircularProgressBar from './CircularProgressBar'
import AssignManageStaff from './AssignManageStaff'
import AssignStaff from './AssignStaff'
import '../assets/css/index.css';
// import OrganizationFilter from './components/organizationfilter'
// import OrganizationList from './components/organizationlist'
// import UserFilter from './components/userfilter'
// import UserList from './components/userlist'



const VisitManagement = () => {
	const [percentage, setPercentage] = useState(20);

	return(
		<div className="container-fluid mt-1" id='svgContainer'>
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right'>
                    <button type="button" class="btn btn-outline-success btn-sm mb-0 me-3" data-bs-toggle="modal" data-bs-target="#manageVisit">Manage Visits</button>
                    <div class="modal fade" id="manageVisit" tabindex="-1" role="dialog" aria-labelledby="manageVisitTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Manage Visit</h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <AssignManageStaff />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="manageVisitAssign" tabindex="-1" role="dialog" aria-labelledby="manageVisitAssignTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Manage Visit</h5>
                                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <AssignStaff />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <div className="card card-default">
                        <div className="card-header">
                            <h4>Visit Summery (All)</h4>
                        </div>
                        <div className="row card-body">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Unallocated"
                                titleCounter="0"
                                percentage={percentage} />
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Early"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="On-time"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Late"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Missed"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Cancelled"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Overall"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText=""
                                titleCounter="0"
                                percentage={percentage}/>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card card-default">
                        <div className="card-header">
                            <h4>Staff Members & Service Users  (All)</h4>
                        </div>
                        <div className="row card-body">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Unallocated"
                                titleCounter="0"
                                percentage={percentage} />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Early"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="On-time"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center mt-5">
                                <CircularProgressBar
                                // strokeWidth="4"
                                // sqSize="150"
                                titleText="Late"
                                titleCounter="0"
                                percentage={percentage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default VisitManagement;