import React from 'react'

export default function EmplopyeeStarterLeaver() {
  return (
    <div className='row'>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">Today's Money</p>
					<h5 className="font-weight-bolder mb-0">
					  $53,000
					  <span className="text-success text-sm font-weight-bolder">+55%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">Today's Users</p>
					<h5 className="font-weight-bolder mb-0">
					  2,300
					  <span className="text-success text-sm font-weight-bolder">+3%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">New Clients</p>
					<h5 className="font-weight-bolder mb-0">
					  +3,462
					  <span className="text-danger text-sm font-weight-bolder">-2%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">Sales</p>
					<h5 className="font-weight-bolder mb-0">
					  $103,430
					  <span className="text-success text-sm font-weight-bolder">+5%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">Sales</p>
					<h5 className="font-weight-bolder mb-0">
					  $103,430
					  <span className="text-success text-sm font-weight-bolder">+5%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
		<div className="col-xl-4 col-sm-6 mb-xl-0 mt-1">
		  <div className="card">
			<div className="card-body p-3">
			  <div className="row">
				<div className="col-8">
				  <div className="numbers">
					<p className="text-sm mb-0 text-capitalize font-weight-bold">Sales</p>
					<h5 className="font-weight-bolder mb-0">
					  $103,430
					  <span className="text-success text-sm font-weight-bolder">+5%</span>
					</h5>
				  </div>
				</div>
				<div className="col-4 text-end">
				  <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
					<i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		</div>
    </div>
  )
}
