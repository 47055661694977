
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logout } from "../auth/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Logoutpage() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
      if (loading) {
        // maybe trigger a loading screen
        return;
      }
      if (user) {
        logout();
        navigate("/login");
      }
    }, [user, loading]);
    return (
        <div>logoutpage</div>
    )
}
