import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    retrieveOrganization,
    createOrganization,
} from "../actions/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const OrganizationCreate = forwardRef((props, ref) => {
	const dispatch = useDispatch();
	// console.log(show);
	const initialOrganizationData = {
		organization_name: "",
		website: "",
		tax_id: "",
		organization_type: ""
	};
	const [organization_data, set_organization_data] = React.useState(initialOrganizationData);
	const [showModal, setShowModal] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	React.useImperativeHandle(ref, () => ({
		showOrganizationCreateChildModal(single_organization_data) {
			showOrganizationCreateModal();
			set_organization_data({
				sl_m_organization_profile: single_organization_data.sl_m_organization_profile,
				organization_name: single_organization_data.organization_name,
				website: single_organization_data.website,
				tax_id: single_organization_data.tax_id,
				organization_type: single_organization_data.organization_type
			});
		},
	}))
	const handleChange = (event) => {
		const { name, value } = event.target;
		set_organization_data({ ...organization_data, [name]: value });
	}
	const showOrganizationCreateModal = () => {
		set_organization_data(initialOrganizationData);
	    setShowModal('show');
	}
	const hideOrganizationCreateModal = () => {
	    setShowModal('');
	}
	const handleSubmitNewOrganizationCreateFunc = () => {
		dispatch(createOrganization(organization_data))
		.then(data => {
			console.log(data);
			setSubmitted(true);
			if(data.res > 0 || data.count == 1) {
				hideOrganizationCreateModal();
				toast.success(data.msg);
				// set_organization_data(initialOrganizationData);
				dispatch(retrieveOrganization({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}


	return(
		<div>
			<button type="button" className="btn btn-primary btn-sm pull-right mt-1 mb-1" onClick={showOrganizationCreateModal}>
				Create
			</button>
			{(showModal == 'show')?<div className="modal-backdrop fade show"></div>:''}
			<div className={`modal fade f-OpenSans fs-14px ${showModal} `} tabIndex="-1" role="dialog" aria-hidden="true" id="organizationCreate" style={{display: (showModal == 'show')?"block":"none"}}>
				<div className="modal-dialog modal-dialog-centered modal-lg">
				    <div className="modal-content">
						<div className="modal-header">
					        <h5 className="modal-title f-OpenSans fs-14px" id="organizationCreateTitle">Create Organization</h5>
					        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideOrganizationCreateModal}></button>
						</div>
						<div className="modal-body">
					        <form action="POST" name="organization_form">
								<div className="container">
				       				{/* <h6>Organization info :</h6> */}
								  	<div className="mb-2">
								    	<label className="form-label">Organization Name</label>
								    	<input type="text" className="form-control form-control-sm" id="organization_name" name="organization_name" value={organization_data.organization_name} onChange={handleChange}></input>
								  	</div>
							  		<div className="mb-2">
							   	 		<label className="form-label">Website</label>
							    		<input type="text" className="form-control form-control-sm" id="website" name="website" value={organization_data.website} onChange={handleChange}></input>
							  		</div>
								</div>
								<div className="container">
								  	<div className="row">
								  		<div className="mb-2 col-md-6">
								    		<label className="form-label">Tax ID</label>
								    		<input type="text" className="form-control form-control-sm" id="tax_id" name="tax_id" value={organization_data.tax_id} onChange={handleChange}></input>
								  		</div>
								  		<div className="mb-2 col-md-6">
										    <label className="form-label">Organization Type</label>
										     <select className="form-select form-select-sm" id="organization_type" name="organization_type" value={organization_data.organization_type} onChange={handleChange}>
										    	<option value="">-select-</option>
										    	<option value="Governmental Organization">Governmental Organization</option>
										    	<option value="Sobrato Center for Nonprofit Tenant">Sobrato Center for Nonprofit Tenant</option>
										    	<option value="Sobrato Foundation">Sobrato Foundation</option>
										    </select>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer">
					        <button type="button" className="btn btn-secondary f-OpenSans fs-12px" data-bs-dismiss="modal" onClick={hideOrganizationCreateModal}>Close</button>
					        <button type="button" className="btn btn-primary f-OpenSans fs-12px" onClick={handleSubmitNewOrganizationCreateFunc}>Save changes</button>
						</div>
				    </div>
				</div>
			</div>
		</div>
	);
})

export default OrganizationCreate;

