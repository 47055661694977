import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import UserCreate from './usercreate';
import {
    deleteUser,
    updateUser,
    retrieveUsers,
} from "../actions/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserList = () => {
	const user_data = useSelector(state => state.UserTabReducer, shallowEqual);
	// console.log(user_data)
	const dispatch = useDispatch();
	const child = useRef();

	useEffect(() => {
		let data = {};
		dispatch(retrieveUsers(data));
	}, []);



	const handleChangeStatusUserRecord = (index) => {
		dispatch(updateUser({status: ((user_data.data[index].status == 0)?1:0), sl: user_data.data[index].sl_m_user_profile}))
		.then(data => {
			if(data.res > 0 || data.count == 1) {
				toast.success(data.msg);
				dispatch(retrieveUsers({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}
	const handleEditUserRecord = (index) => {
		if (child.current) {
			child.current.showUserCreateChildModal(user_data.data[index])
		}
	}
	const handleTrashUserRecord = (index) => {
		dispatch(deleteUser({is_delete: (user_data.data[index].is_delete == 0)?1:0, sl: user_data.data[index].sl_m_user_profile}))
		.then(data => {
			if(data.res > 0 || data.count == 1) {
				toast.success(data.msg);
				dispatch(retrieveUsers({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}


	return(
		<div>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12 mt-2'>
						<UserCreate ref={child} />
					</div>
					<div className='col-md-12 mt-2'>
						<div className='card card-default'>
							<div className='card-body'>
								<table className='table table-responsive table-striped'>
									<thead>
									<tr>
										<th>#</th>
										<th>Name</th>
										<th>Email</th>
										{/* <th>Status</th> */}
										<th>Tools</th>
										<th>Actions</th>
									</tr>
									</thead>
									<tbody>
									{

									(user_data.length == 0)?
									<tr>
										<td colSpan={5} style={{textAlign: 'center'}}>No Data Found</td>
									</tr>
									:
									(user_data.data.length == 0)?
									<tr>
										<td colSpan={5} style={{textAlign: 'center'}}>No Data Found</td>
									</tr>
									:
									user_data.data && 
										user_data.data.map((value, index) => {
											return (
											<tr key={index}>
												<td>{(index+1)}</td>
												<td>{value.username}</td>
												<td>{value.contact_email}</td>
												<td>
													<button className={`btn btn-sm ${(value.status == 1)?"btn-success":"btn-danger"} fa fa-circle`} onClick={() => handleChangeStatusUserRecord(index)}>{(value.status == 1)?"Active":"Inactive"}</button>
												</td>
												<td>
												<div className="container-fluid">
													<div className="row">
													<div className="col-md-6 text-center">
														<button className='btn btn-sm btn-warning fa fa-circle' onClick={() => handleEditUserRecord(index)}>Edit</button>
													</div>
													<div className="col-md-6 text-center">
														<button className='btn btn-sm btn-warning fa fa-circle' style={{marginRight: "0"}} onClick={() => handleTrashUserRecord(index)}>Delete</button>
													</div>
													</div>
												</div>
												</td>
												<td><button className='btn btn-sm btn-primary' type='button'>Action</button></td>
											</tr>
											);
										})
									}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>
	);
}

export default UserList;

