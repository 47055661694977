
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    retrieveOrganization,
	updateOrganization,
	deleteOrganization,
} from "../actions/index";

import OrganizationCreate from './organizationcreate';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const OrganizationList = () => {
	const organization_data = useSelector(state => state.OrganizationTabReducer, shallowEqual);

	const child = useRef();

	const dispatch = useDispatch();

	useEffect(() => {
		let data = {};
		dispatch(retrieveOrganization(data));
	}, []);


	const handleChangeStatusOrganizationRecord = (index) => {
		dispatch(updateOrganization({status: ((organization_data.data[index].status == 0)?1:0), sl: organization_data.data[index].sl_m_organization_profile}))
		.then(data => {
			if(data.res > 0 || data.count == 1) {
				toast.success(data.msg);
				dispatch(retrieveOrganization({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}
	const handleEditOrganizationRecord = (index) => {
		if (child.current) {
			child.current.showOrganizationCreateChildModal(organization_data.data[index])
		}
	}
	const handleTrashOrganizationRecord = (index) => {
		dispatch(deleteOrganization({is_delete: (organization_data.data[index].is_delete == 0)?1:0, sl: organization_data.data[index].sl_m_organization_profile}))
		.then(data => {
			if(data.res > 0 || data.count == 1) {
				toast.success(data.msg);
				dispatch(retrieveOrganization({}));
			} else {
				toast.error(data.msg);
			}
		}).catch(e => {
			console.log(e);
		});
	}


	return(
		<div>
			<div className='container'>
		      <div className='row'>
		        <div className='col-md-12'>
					<OrganizationCreate ref={child}  />
				</div>
		        <div className='col-md-12'>
		          <div className='card card-default'>
		            {/* <div className='card-header'>
		              <div className="container-fluid p2">
		                <div className="row">
		                  <div className="col-md-6 text-left">
		                    <input className='form-control' id="search" name="search" />
		                  </div>
		                  <div className="col-md-6 text-right">
		                    <button className='btn btn-sm btn-primary fa fa-circle'>Add</button>
		                  </div>
		                </div>
		              </div>
		            </div> */}
		            <div className='card-body'>
		              <table className='table table-responsive table-striped f-OpenSans fw-600'>
		                <thead>
		                  <tr>
		                    <th>#</th>
		                    <th>Organization Name</th>
		                    <th>Website</th>
		                    <th>Tax Id</th>
		                    <th>Status</th>
		                    <th>Tools</th>
		                    <th>Actions</th>
		                  </tr>
		                </thead>
		                <tbody>
		                {
						(organization_data.length == 0)?
						<tr>
							<td colSpan={7} style={{textAlign: 'center'}}>No Data Found</td>
						</tr>
						:
						(organization_data.data.length == 0)?
						<tr>
							<td colSpan={7} style={{textAlign: 'center'}}>No Data Found</td>
						</tr>
						:
		                organization_data.data &&
		                    organization_data.data.map((value, index) => {
		                        return (
		                          <tr key={index}>
		                            <td>{(index+1)}</td>
		                            <td>{value.organization_name}</td>
		                            <td>{value.website}</td>
		                            <td>{value.tax_id}</td>
		                            <td>
										<button className={`btn btn-sm ${(value.status == 1)?"btn-success":"btn-danger"} fa fa-circle`} onClick={() => handleChangeStatusOrganizationRecord(index)}>{(value.status == 1)?"Active":"Inactive"}</button>
		                            </td>
		                            <td>
		                              <div className="container-fluid">
		                                <div className="row">
		                                  <div className="col-md-6 text-center">
		                                    <button className='btn btn-sm btn-warning fa fa-circle' onClick={() => handleEditOrganizationRecord(index)}>Edit</button>
		                                  </div>
		                                  <div className="col-md-6 text-center">
		                                    <button className='btn btn-sm btn-warning fa fa-circle' style={{marginRight: "0"}} onClick={() => handleTrashOrganizationRecord(index)}>Delete</button>
		                                  </div>
		                                </div>
		                              </div>
		                            </td>
		                            <td><button className='btn btn-sm btn-primary' type='button'>Action</button></td>
		                          </tr>
		                        );
		                    })
		                // :
		                //   <tr>
		                //     <td colSpan={6} style={{textAlign: 'center'}}>No Data Found</td>
		                //   </tr>
		                }
		                </tbody>
		              </table>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		</div>
	);
}

export default OrganizationList;




