import React, {useState} from 'react'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import "flatpickr/dist/themes/material_green.css";
import { TimePicker, Upload, Button, Select, Form } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'antd/dist/reset.css';

// dayjs.extend(customParseFormat);




import Flatpickr from "react-flatpickr";




export default function AssignManageStaff() {
    const { Option } = Select;
    const [open, setOpen] = useState('')
  
    const handleChange = value => {
      console.log(`selected ${value}`)
    }
    const onChange = (time: Dayjs, timeString: string) => {
        console.log(time, timeString);
    };
    return (
        <div className='row'>
            <div className='col-md-4'>
                <div className="card text-left">
                    <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Author</th>
                                    <th className="text-secondary opacity-7 text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">John Michael</h6>
                                                <p className="text-xs text-secondary mb-0">john@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Alexa Liras</h6>
                                                <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Laurent Perrier</h6>
                                                <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Michael Levi</h6>
                                                <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Richard Gran</h6>
                                                <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Miriam Eric</h6>
                                                <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            <div className='col-md-4'>
                <div className="card text-left">
                    <div className="table-responsive">
                        <table className="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Author</th>
                                    <th className="text-secondary opacity-7 text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">John Michael</h6>
                                                <p className="text-xs text-secondary mb-0">john@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Alexa Liras</h6>
                                                <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Laurent Perrier</h6>
                                                <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        {/* <button className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user"> */}
                                        <i className='ni ni-bold-right'></i>
                                        {/* </button> */}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Michael Levi</h6>
                                                <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Richard Gran</h6>
                                                <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div className="d-flex px-2 py-1">
                                            <div>
                                                <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="mb-0 text-xs">Miriam Eric</h6>
                                                <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-middle text-center">
                                        <i className='ni ni-bold-right'></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            <div className='col-md-4'>
                <div className="card text-left p-2">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <h4>Define Schedule</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                {/* <input class="form-control datepicker" placeholder="Please select date" type="text" onfocus="focused(this)" onfocusout="defocused(this)" /> */}
                                <Flatpickr className='form-control'
        data-enable-time
        mode='range'
        value={new Date()}
        options={{ mode: 'range', datepicker: true, timepicker: false, dateFormat: "M d, Y", }}
        placeholder="M d, Y"
        // onChange={([date]) => {
        //   this.setState({ date });
        // }}
      />
                            {/* <input type="email" class="form-control form-control-alternative" id="exampleFormControlInput1" placeholder="name@example.com"> */}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <TimePicker className='form-control' use12Hours onChange={onChange} style={{zIndex: 99}} defaultOpenValue={dayjs('00:00', 'HH:mm')} />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <TimePicker className='form-control' use12Hours onChange={onChange} style={{zIndex: 99}} defaultOpenValue={dayjs('00:00', 'HH:mm')} />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <Select mode="multiple" style={{ width: '100%' }} placeholder="Select Working Days"
                                    optionFilterProp="children"
                                    open={open}
                                    onChange={value => {
                                        handleChange.bind(this, value)
                                        this.setState({ open: false })
                                    }}
                                    onFocus={() => setOpen(true)}
                                    onBlur={() => setOpen(false)}
                                    onSearch={() => setOpen(true)}
                                    >
                                    <Option value="Monday">Monday</Option>
                                    <Option value="Tuesday">Tuesday</Option>
                                    <Option value="Wednesday">Wednesday</Option>
                                    <Option value="Thursday">Thursday</Option>
                                    <Option value="Friday">Friday</Option>
                                    <Option value="Saturday">Saturday</Option>
                                    <Option value="Sunday">Sunday</Option>
                                </Select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <Upload name="logo" action="/upload.do" listType="picture">
                                    <Button icon={<UploadOutlined />}>Upload Document (Optional)</Button>
                                </Upload>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <h4>Pick Staff</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <Select mode="multiple" style={{ width: '100%' }} placeholder="Select Working Days"
                                    optionFilterProp="children"
                                    open={open}
                                    onChange={value => {
                                        handleChange.bind(this, value)
                                        this.setState({ open: false })
                                    }}
                                    onFocus={() => setOpen(true)}
                                    onBlur={() => setOpen(false)}
                                    onSearch={() => setOpen(true)}
                                    >
                                    <Option value="1">Sandip Kundu</Option>
                                    <Option value="2">Reshab Sahani</Option>
                                    </Select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <h4>Select Activities</h4>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <Select mode="multiple" style={{ width: '100%' }} placeholder="Select Working Days"
                                    optionFilterProp="children"
                                    open={open}
                                    onChange={value => {
                                        handleChange.bind(this, value)
                                        this.setState({ open: false })
                                    }}
                                    onFocus={() => setOpen(true)}
                                    onBlur={() => setOpen(false)}
                                    onSearch={() => setOpen(true)}
                                    >
                                        <Option value="1">Medicine</Option>
                                        <Option value="2">Care</Option>
                                    </Select>
                            </div>
                        </div>
                    </div>
                </form>
                </div >
            </div >
        </div>
    )
}
