// https://blog.logrocket.com/user-authentication-firebase-react-apps/



import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../auth/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SideImg from "../assets/sideimage.svg"
import AvatarImg from "../assets/avatar.svg"
import "./loginpage.css"

// import "./Login.css";
function Login() {
  const [rememberMe, setRememberMe] = React.useState(false);

  const handleChange = () => {
    setRememberMe(!rememberMe);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  
  const resetFields = (e) => {
    if (e.target.name == 'email')
      setEmail("");
    if (e.target.name == 'password')
      setPassword("");
  }


  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <>
    {/* <div className="container position-sticky z-index-sticky top-0">
    <div className="row">
      <div className="col-12">
        <nav className="navbar navbar-expand-lg blur blur-rounded top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
          <div className="container-fluid pe-0">
            <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 " href="../pages/dashboard.html">
              Soft UI Dashboard
            </a>
            <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon mt-2">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navigation">
              <ul className="navbar-nav mx-auto ms-xl-auto me-xl-7">
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center me-2 active" aria-current="page" href="../pages/dashboard.html">
                    <i className="fa fa-chart-pie opacity-6 text-dark me-1"></i>
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link me-2" href="../pages/profile.html">
                    <i className="fa fa-user opacity-6 text-dark me-1"></i>
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link me-2" href="../pages/sign-up.html">
                    <i className="fas fa-user-circle opacity-6 text-dark me-1"></i>
                    Sign Up
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link me-2" href="../pages/sign-in.html">
                    <i className="fas fa-key opacity-6 text-dark me-1"></i>
                    Sign In
                  </a>
                </li>
              </ul>
              <li className="nav-item d-flex align-items-center">
                <a className="btn btn-round btn-sm mb-0 btn-outline-primary me-2" target="_blank" href="https://www.creative-tim.com/builder/soft-ui?ref=navbar-dashboard">Online Builder</a>
              </li>
              <ul className="navbar-nav d-lg-block d-none">
                <li className="nav-item">
                  <a href="https://www.creative-tim.com/product/soft-ui-dashboard" className="btn btn-sm btn-round mb-0 me-1 bg-gradient-dark">Free download</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div> */}
  <main className="main-content  mt-0">
    <section>
      <div className="page-header min-vh-85">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 d-flex flex-column mx-auto">
              <div className="card card-plain mt-8">
                <div className="card-header pb-0 text-left bg-transparent">
                  <h3 className="font-weight-bolder text-info text-gradient">Welcome back</h3>
                  <p className="mb-0">Enter your email and password to sign in</p>
                </div>
                <div className="card-body">
                  <form className="row" role="form">
                    <label className=" text-left">Email</label>
                    <div className="mb-3">
                      <input type="email" className="form-control" name="email" placeholder="Email" aria-label="Email" aria-describedby="email-addon" value={email}
                          onChange={(e) => setEmail(e.target.value)} onClick={resetFields} />
                    </div>
                    <label className=" text-left">Password</label>
                    <div className="mb-3">
                      <input type="email" className="form-control" name="password" placeholder="Password" aria-label="Password" aria-describedby="password-addon" value={password}
                          onChange={(e) => setPassword(e.target.value)} onClick={resetFields} />
                    </div>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="remember_me" id="remember_me" checked={rememberMe} onClick={handleChange} onChange={handleChange} />
                      <label className="form-check-label" htmlFor="remember_me">Remember me</label>
                    </div>
                    <div className="text-center">
                      <button type="button" className="btn bg-gradient-info w-100 mt-4 mb-0" onClick={() => logInWithEmailAndPassword(email, password)} >Sign in</button>

                      <button type="button" className="login-with-google-btn w-100 mt-4 mb-0" onClick={signInWithGoogle}>
                      Login with Google
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer text-center pt-0 px-lg-2 px-1">
                  <p className="mb-4 text-sm mx-auto">
                    Don't have an account?
                    <Link to="/register" className="text-info text-gradient font-weight-bold">Sign up</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6 loginBGImg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
    </>
  );
}
export default Login;


