
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logout } from "./auth/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cross404page from './cross404page/cross404page.test';
import Loginpage from './loginpage/loginpage.test';
import Registerpage from './registerpage/registerpage.test';
import Logoutpage from './logoutpage/logoutpage';
// import Homepage from './homepage/homepage.class';
// import Homepage from './homepage/homepage.test';
import Homepage from './homepage/homepage.function';
import VisitManagement from './VisitManagement/VisitManagement.function';
import BusinessDevelopment from './BusinessDevelopment/BusinessDevelopment.function';
import Finance from './Finance/Finance.function';
import Planning from './Planning/Planning.function';
import Employees from './Employees/Employees.function';
import ServiceUsers from './ServiceUsers/ServiceUsers.function';
import Events from './Events/Events.function';
import Messages from './Messages/Messages.function';
import Tasks from './Tasks/Tasks.function';
import Notifications from './Notifications/Notifications.function';
import VisitAlert from './VisitAlert/VisitAlert.function';
import TemporaryDevelopment from './TemporaryDevelopment/TemporaryDevelopment.function';
// import Reservepage from './reservepage/reservepage';
// import Searchpage from './searchpage/searchpage';
// import Usermanagementpage from './usermanagementpage/usermanagementpage';
// import Communicationpage from './communicationpage/communicationpage';
// import Permissionpage from './permissionpage/permissionpage';
// import Reportpage from './reportpage/reportpage';
// import Roomspage from './roomspage/roomspage';
// import Reservation from './reservation/reservation';

import HeaderComponent from './templates/header';
import './App.css';
// var React = require('react');
// var ReactDOM = require('react-dom');
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import App from './App';
// import MainLayout from './components/MainLayout';
// import Home from './components/home/Home';
// import Product from './components/product/Product';
// import Category from './components/category/Category';
// import Cart from './components/cart/Cart';
// import NotFound from './components/NotFound';

const App = () => {
  const [user, loading, error] = useAuthState(auth);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (loading) {
  //     // maybe trigger a loading screen
  //     return;
  //   }
  //   if (user) {
  //     // logout();
  //     // navigate("/login");
  //   }
  // }, [user, loading]);
  return (
    <>
      {/*<React.StrictMode>*/}
      <BrowserRouter>
        {/* {(user) ? <HeaderComponent /> : ''} */}
        <Routes>
          <Route exact path="/" element={(user) ? <><HeaderComponent><Homepage /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/login" element={(user) ? <><HeaderComponent><Homepage /></HeaderComponent></> : <Loginpage />} />
          <Route path="/register" element={(user)?<><HeaderComponent><Homepage /></HeaderComponent></>:<Registerpage />} />
          <Route exact path="/logout" element={<Logoutpage />} />
          <Route exact path="/home" element={(user) ? <><HeaderComponent><Homepage /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/dashboard" element={(user) ?<><HeaderComponent><Homepage /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/VisitManagement" element={(user) ?<><HeaderComponent><VisitManagement /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/BusinessDevelopment" element={(user) ?<><HeaderComponent><BusinessDevelopment /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Finance" element={(user) ?<><HeaderComponent><Finance /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Planning" element={(user) ?<><HeaderComponent><Planning /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Employees" element={(user) ?<><HeaderComponent><Employees /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/ServiceUsers" element={(user) ?<><HeaderComponent><ServiceUsers /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Events" element={(user) ?<><HeaderComponent><Events /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Messages" element={(user) ?<><HeaderComponent><Messages /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Tasks" element={(user) ?<><HeaderComponent><Tasks /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/Notifications" element={(user) ?<><HeaderComponent><Notifications /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/VisitAlert" element={(user) ?<><HeaderComponent><VisitAlert /></HeaderComponent></> : <Loginpage />} />
          <Route exact path="/TemporaryDevelopment" element={(user) ?<><HeaderComponent><TemporaryDevelopment /></HeaderComponent></> : <Loginpage />} />
          {/* <Route exact path="/reserve" element={(user) ? <Reservepage /> : <Loginpage />} />
          <Route exact path="/search" element={(user) ? <Searchpage /> : <Loginpage />} />
          <Route exact path="/usermanagement" element={(user) ? <Usermanagementpage /> : <Loginpage />} />
          <Route exact path="/communication" element={(user) ? <Communicationpage /> : <Loginpage />} />
          <Route exact path="/permission" element={(user) ? <Permissionpage /> : <Loginpage />} />
          <Route exact path="/reservation" element={(user) ? <Reservation /> : <Loginpage />} />
          <Route exact path="/rooms" element={(user) ? <Roomspage /> : <Loginpage />} />
          <Route exact path="/report" element={(user) ? <Reservation /> : <Loginpage />} /> */}
          <Route path="*" element={(user) ? <Cross404page /> : <Cross404page />} />
        </Routes>


        <ToastContainer
          position="bottom-right"
          autoClose={7000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
      {/*</React.StrictMode>*/}
    </>
  )
}


export default App;