import React from 'react'

export default function AssignStaff() {
    return (
        <div>
            <div className="card text-left">
                <div className="table-responsive">
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th className="text-secondary opacity-7 text-center">Choose</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Author</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">John Michael</h6>
                                            <p className="text-xs text-secondary mb-0">john@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">Alexa Liras</h6>
                                            <p className="text-xs text-secondary mb-0">alexa@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">Laurent Perrier</h6>
                                            <p className="text-xs text-secondary mb-0">laurent@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-3.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">Michael Levi</h6>
                                            <p className="text-xs text-secondary mb-0">michael@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-2.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">Richard Gran</h6>
                                            <p className="text-xs text-secondary mb-0">richard@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className="align-middle text-center">
                                    <i className='ni ni-bold-right'></i>
                                </td>
                                <td>
                                    <div className="d-flex px-2 py-1">
                                        <div>
                                            <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/team-4.jpg" className="avatar avatar-sm me-3" />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="mb-0 text-xs">Miriam Eric</h6>
                                            <p className="text-xs text-secondary mb-0">miriam@creative-tim.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}
