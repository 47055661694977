import {
    UserTabReducer, 
} from "./usermanagementpage/reducer/usertabreducer";
import {
    OrganizationTabReducer, 
} from "./usermanagementpage/reducer/organizationtabreducer";
import {
    ResourceTabReducer, 
} from "./roomspage/reducer/resourcetabreducer";
import {
    LocationTabReducer, 
} from "./roomspage/reducer/locationtabreducer";
import {
    ReservePageReducer, ReservePageUIReducer,
} from "./reservepage/reducer/reservepagereducer";

import {combineReducers} from "redux";


const rootReducer = combineReducers({
    UserTabReducer: UserTabReducer, 
    OrganizationTabReducer: OrganizationTabReducer, 
    ResourceTabReducer: ResourceTabReducer, 
    LocationTabReducer: LocationTabReducer, 
    ReservePageReducer: ReservePageReducer,
    ReservePageUIReducer: ReservePageUIReducer,
});

export default rootReducer;
