

import React from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import calendarInteraction from '@fullcalendar/interaction';

function CalendarResourceTimeline() {

    return (
        <div>
            <FullCalendar
                schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                plugins={[calendarInteraction, resourceTimelinePlugin]}
                initialView={'resourceTimelineMonth'}
                selectable={true}
                editable={true}
                resourceAreaHeaderContent={'Users'}
                resourceAreaWidth={'10%'}
                header={{
                left: "prev,next today",
                center: "title",
                right: "resourceTimelineWeek,dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                }}
                resources='https://fullcalendar.io/demo-resources.json?with-nesting&with-colors'
                // resources={this.state.groups}
                // events={this.state.items}
                events='https://fullcalendar.io/demo-events.json?single-day&for-resource-timeline'
                // ref={this.calendarOneRef}
                // weekends={calendarWeekends}
                // events={calendarEvents}
                // dateClick={this.handleDateClick}
                // eventClick={this.handleEventClick}
                // select={this.handleSelect}
                // dayClick={this.handleDayClick}
                // selectOverlap={this.handleSelectOverlap}
                // eventDragStop={this.handleEventDragStop}
                // eventDragStart={this.handleEventDragStart}
        />
        </div>
    );
}
export default CalendarResourceTimeline;