// import React from 'react';


// const HeaderComponent = () => {
// 	return(
// 	);
// }

// export default HeaderComponent;



import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';


const HeaderComponent = ({children}) => {
	return (
		<>
			<aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 " id="sidenav-main">
				<div className="sidenav-header">
					<i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
					<Link className="navbar-brand m-0" to=" https://demos.creative-tim.com/soft-ui-dashboard/pages/dashboard.html "  rel="noreferrer" target="_blank">
						{/* <img src="../assets/img/logo-ct-dark.png" className="navbar-brand-img h-100" alt="main_logo" /> */}
							<span className="ms-1 font-weight-bold">Soft UI Dashboard</span>
					</Link>
				</div>
				<hr className="horizontal dark mt-0" />
				<div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
					<ul className="navbar-nav">
						<li className="nav-item">
							<Link className="nav-link active" to="/dashboard">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Dashboard</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/VisitManagement">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Visit Management</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/BusinessDevelopment">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Business Development</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Finance">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Finance</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Planning">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Planning</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Employees">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Employees</span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/ServiceUsers">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Service Users</span>
							</Link>
						</li>
						<li className="nav-item mt-3">
							<h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Actions</h6>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Events">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Events <b className='badge fg-white'>0</b></span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Messages">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Messages <b className='badge fg-white'>0</b></span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Tasks">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Tasks <b className='badge fg-white'>0</b></span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/Notifications">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Notifications <b className='badge fg-white'>0</b></span>
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/VisitAlert">
								<div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
									<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
								</div>
								<span className="nav-link-text ms-1">Visit Alert <b className='badge fg-white'>0</b></span>
							</Link>
						</li>
					</ul>
				</div>
			</aside>
			<main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
				<nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
					<div className="container-fluid py-1 px-3">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
								<li className="breadcrumb-item text-sm"><Link className="opacity-5 text-dark" to="javascript:void(0);">Pages</Link></li>
								<li className="breadcrumb-item text-sm text-dark active" aria-current="page">Dashboard</li>
							</ol>
							<h6 className="font-weight-bolder mb-0">Dashboard</h6>
						</nav>
						<div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
							<div className="ms-md-auto pe-md-3 d-flex align-items-center">
								<div className="input-group">
									<span className="input-group-text text-body"><i className="fas fa-search" aria-hidden="true"></i></span>
									<input type="text" className="form-control" placeholder="Type here..." />
								</div>
							</div>
							<ul className="navbar-nav  justify-content-end">
								<li className="nav-item d-flex align-items-center">
									<Link to="javascript:void(0);" className="nav-link text-body font-weight-bold px-0">
										<i className="fa fa-user me-sm-1"></i>
										<span className="d-sm-inline d-none">Sign In</span>
									</Link>
								</li>
								<li className="nav-item px-3 d-flex align-items-center">
									<Link to="javascript:void(0);" className="nav-link text-body p-0">
										<i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
									</Link>
								</li>
								<li className="nav-item dropdown pe-2 d-flex align-items-center">
									<Link to="javascript:void(0);" className="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
										<i className="fa fa-bell cursor-pointer"></i>
									</Link>
									<ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
										<li className="mb-2">
											<Link className="dropdown-item border-radius-md" to="javascript:void(0);">
												<div className="d-flex py-1">
													{/* <div className="my-auto">
														<img src="../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 ">
													</div> */}
													<div className="d-flex flex-column justify-content-center">
														<h6 className="text-sm font-weight-normal mb-1">
															<span className="font-weight-bold">New message</span> from Laur
														</h6>
														<p className="text-xs text-secondary mb-0 ">
															<i className="fa fa-clock me-1"></i>
															13 minutes ago
														</p>
													</div>
												</div>
											</Link>
										</li>
										<li className="mb-2">
											<Link className="dropdown-item border-radius-md" to="javascript:void(0);">
												<div className="d-flex py-1">
													{/* <div className="my-auto">
														<img src="../assets/img/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  me-3 ">
													</div> */}
													<div className="d-flex flex-column justify-content-center">
														<h6 className="text-sm font-weight-normal mb-1">
															<span className="font-weight-bold">New album</span> by Travis Scott
														</h6>
														<p className="text-xs text-secondary mb-0 ">
															<i className="fa fa-clock me-1"></i>
															1 day
														</p>
													</div>
												</div>
											</Link>
										</li>
										<li>
											<Link className="dropdown-item border-radius-md" to="javascript:void(0);">
												<div className="d-flex py-1">
													<div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
														<i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
													</div>
													<div className="d-flex flex-column justify-content-center">
														<h6 className="text-sm font-weight-normal mb-1">
															Payment successfully completed
														</h6>
														<p className="text-xs text-secondary mb-0 ">
															<i className="fa fa-clock me-1"></i>
															2 days
														</p>
													</div>
												</div>
											</Link>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</nav>

													
				<div className="container-fluid py-4">
					<div className="row">
						{children}
					</div>
					
					{/* <footer className="footer pt-3  ">
						<div className="container-fluid">
							<div className="row align-items-center justify-content-lg-between">
								<div className="col-lg-6 mb-lg-0 mb-4">
									<div className="copyright text-center text-sm text-muted text-lg-start">
										© <script>
											document.write(new Date().getFullYear())
										</script>,
										made with <i className="fa fa-heart"></i> by
										<Link to="https://www.creative-tim.com" className="font-weight-bold"  rel="noreferrer" target="_blank">Creative Tim</Link>
										for a better web.
									</div>
								</div>
								<div className="col-lg-6">
									<ul className="nav nav-footer justify-content-center justify-content-lg-end">
										<li className="nav-item">
											<Link to="https://www.creative-tim.com" className="nav-link text-muted"  rel="noreferrer" target="_blank">Creative Tim</Link>
										</li>
										<li className="nav-item">
											<Link to="https://www.creative-tim.com/presentation" className="nav-link text-muted"  rel="noreferrer" target="_blank">About Us</Link>
										</li>
										<li className="nav-item">
											<Link to="https://www.creative-tim.com/blog" className="nav-link text-muted"  rel="noreferrer" target="_blank">Blog</Link>
										</li>
										<li className="nav-item">
											<Link to="https://www.creative-tim.com/license" className="nav-link pe-0 text-muted"  rel="noreferrer" target="_blank">License</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</footer> */}
				</div>
			</main>
		</>
	);
	// return <>1</>
}

export default HeaderComponent;




{/* <nav className="navbar navbar-expand-lg bg-light">
	<div className="container-fluid">
	<NavLink
		to="/"
		className={isActive =>
		"navbar-brand" + (!isActive ? " active" : "")
		}
	>
		SOBRATO
	</NavLink>
	<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<span className="navbar-toggler-icon"></span>
	</button>
	<div className="collapse navbar-collapse" id="navbarSupportedContent">
		<ul className="navbar-nav me-auto mb-2 mb-lg-0">
		<li className="nav-item">
			<NavLink
				to="/reserve"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Reserve
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/search"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Search
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/rooms"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Rooms
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/permission"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Confirm &amp; Approve
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/report"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Report
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/usermanagement"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				User Management
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/communication"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Commucation
			</NavLink>
		</li>
		<li className="nav-item">
			<NavLink
				to="/logout"
				className={isActive =>
				"nav-link" + (!isActive ? " active" : "")
				}
			>
				Logout
			</NavLink>
		</li>
		</ul>
	</div>
	</div>
</nav> */}