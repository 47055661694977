import {
    CREATE_RESOURCE,
    RETRIEVE_RESOURCE,
    UPDATE_RESOURCE,
    DELETE_RESOURCE,
  } from "../actions/type";
  const initialState = [];
  function ResourceTabReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_RESOURCE:
        return {data: [...state.data], payload};
      case RETRIEVE_RESOURCE:
        // console.log((payload.error));
        state = (payload.error == 1)?initialState:payload;
        // console.log(state);
        return {data: [...state], payload};
      case UPDATE_RESOURCE:
        return {data: [...state.data], payload};
      case DELETE_RESOURCE:
        return {data: [...state.data], payload};
      default:
        return state;
    }
  };
  export {ResourceTabReducer};