

// https://www.bezkoder.com/react-crud-web-api/          For Class Components
// https://www.bezkoder.com/react-hooks-redux-crud/      For Functional Components



import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    retrieveAllUnknownTopics,
} from "./actions/index";
const Homepage = () => {
  const unknown_topics = useSelector(state => state.HomepageReducer);
//   console.log(unknown_topics);
  const dispatch = useDispatch();
  useEffect(() => {
      
    let data = {
        "condition_array": [{
            business_id: 1,
            parent_id: 0,
        }],
    };
    dispatch(retrieveAllUnknownTopics(data));
  }, []);
//   const onChangeSearchTitle = e => {
//     const searchTitle = e.target.value;
//     setSearchTitle(searchTitle);
//   };
//   const setActiveUnknownTopic = (unknown_topic, index) => {
//     setCurrentUnknownTopic(unknown_topic);
//     setCurrentIndex(index);
//   };
//   const removeAllUnknownTopics = () => {
//     dispatch(deleteAllAllUnknownTopics())
//       .then(response => {
//         console.log(response);
//         refreshData();
//       })
//       .catch(e => {
//         console.log(e);
//       });
//   };
//   const findByTitle = () => {
//     refreshData();
//     dispatch(findAllUnknownTopicsByTitle(searchTitle));
//   };

  return (
    <>
    <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <div className="numbers">
                <p className="text-sm mb-0 text-capitalize font-weight-bold">Today's Money</p>
                <h5 className="font-weight-bolder mb-0">
                  $53,000
                  <span className="text-success text-sm font-weight-bolder">+55%</span>
                </h5>
              </div>
            </div>
            <div className="col-4 text-end">
              <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <div className="numbers">
                <p className="text-sm mb-0 text-capitalize font-weight-bold">Today's Users</p>
                <h5 className="font-weight-bolder mb-0">
                  2,300
                  <span className="text-success text-sm font-weight-bolder">+3%</span>
                </h5>
              </div>
            </div>
            <div className="col-4 text-end">
              <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                <i className="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <div className="numbers">
                <p className="text-sm mb-0 text-capitalize font-weight-bold">New Clients</p>
                <h5 className="font-weight-bolder mb-0">
                  +3,462
                  <span className="text-danger text-sm font-weight-bolder">-2%</span>
                </h5>
              </div>
            </div>
            <div className="col-4 text-end">
              <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                <i className="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-xl-3 col-sm-6">
      <div className="card">
        <div className="card-body p-3">
          <div className="row">
            <div className="col-8">
              <div className="numbers">
                <p className="text-sm mb-0 text-capitalize font-weight-bold">Sales</p>
                <h5 className="font-weight-bolder mb-0">
                  $103,430
                  <span className="text-success text-sm font-weight-bolder">+5%</span>
                </h5>
              </div>
            </div>
            <div className="col-4 text-end">
              <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                <i className="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='col-xl-12 col-sm-12 mt-5'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card card-default'>
            <div className='card-header'>
              <div className="container-fluid p2">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <input className='form-control' id="search" name="search" />
                  </div>
                  <div className="col-md-6 text-right">
                    <button className='btn btn-sm btn-primary text-sm opacity-10'>Add</button>
                    <button className='btn btn-sm btn-primary text-sm opacity-10 float-right' data-original-title="Popover On Left">Filter</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <table className='table table-responsive table-striped'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    {/* <th>Status</th> */}
                    <th>Tools</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {
                unknown_topics &&
                    unknown_topics.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td>{(index+1)}</td>
                            <td>{value.title}</td>
                            <td>{value.type}</td>
                            {/* <td><button className={`btn btn-sm btn-success fa fa-circle`}  onClick={() => this.changeStatusAllUnknownTopic(((value.status == 1)?0:1), index)}>{(value.status == 1)?'Active':'Inactive'}</button></td> */}
                            <td>
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-6 text-center">
                                    <button className='btn btn-sm btn-warning fa fa-circle'>Edit</button>
                                  </div>
                                  <div className="col-md-6 text-center">
                                    <button className='btn btn-sm btn-warning fa fa-circle' style={{marginRight: "0"}}>Delete</button>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td><button className='btn btn-sm btn-primary' type='button'>Action</button></td>
                          </tr>
                        );
                    })
                // :
                //   <tr>
                //     <td colSpan={6} style={{textAlign: 'center'}}>No Data Found</td>
                //   </tr>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default Homepage;